import axios from 'axios';
import { api_addr } from './urls';
export async function authenticateWithInitData(initData) {
    try {
        const response = await axios.post(api_addr + '/registerOrAuthenticate', initData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const { accessToken, refreshToken } = response.data;

        return { accessToken, refreshToken };
    } catch (error) {
        console.error('Authentication failed:', error);
    }
}