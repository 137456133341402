import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPriceRange } from '../../../store/actions/filterActions';
import classes from "./Range.module.css";
import {useTelegram} from "../../../hooks/useTelegram";
import {text} from "../../../translate";

const Range = () => {
    const dispatch = useDispatch();
    const {lang} = useTelegram();
    const [inputFrom, setInputFrom] = useState('');
    const [inputTo, setInputTo] = useState('');
    const range = useSelector(state => state?.filter?.priceRange);
    
    useEffect(() => {
        if(range?.from){
            setInputFrom(range?.from);
        }
        if(range?.to){
            setInputTo(range?.to); 
        }
    }, []);
    
    const handleInputFromChange = (e) => {
        let value = e.target.value;
        // Предотвращение ввода нескольких нулей и нулей в начале числа
        if (!isNaN(value) && (value === "" || parseInt(value) > 0)) {
            setInputFrom(value);
        }
    };

    const handleInputToChange = (e) => {
        let value = e.target.value;
        // Предотвращение ввода нескольких нулей и нулей в начале числа
        if (!isNaN(value) && (value === "" || parseInt(value) > 0)) {
            setInputTo(value);
        }
    }
    const handleInputBlur = () => {
        dispatch(setPriceRange({ from: inputFrom, to: inputTo }));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Предотвращаем отправку формы
            //handleInputBlur();
            e.target.blur(); // Вызываем функцию при нажатии Enter
        }
    };
    return (
        <div className={classes._}>
            <div>{text[lang].filter.price}</div>
            <div>{text[lang].filter.from}</div>
            <input className={classes.input}
                   type={"number"}
                   placeholder={'0'}
                   value={inputFrom}
                   onChange={handleInputFromChange}
                   onBlur={handleInputBlur}
                   onKeyDown={handleKeyPress}
            />
            <div>{text[lang].filter.to}</div>
            <input className={classes.input}
                   type={"number"}
                   placeholder={'700 000'}
                   value={inputTo}
                   onChange={handleInputToChange}
                   onBlur={handleInputBlur}
                   onKeyDown={handleKeyPress}
            />
        </div>
    );
};

export default Range;