import React from "react";
import { createRoot } from 'react-dom';
import configureStore from './store/store';
import { Provider } from 'react-redux';
import { useCloudStorage } from './hooks/useCloudStorage';
import { useTelegram } from './hooks/useTelegram';

import "./index.css";
import App from "./App";
const Root = () => {
  const cloudStorageResult = useCloudStorage();
  const telegramResult = useTelegram();

  const reduxStore = configureStore(cloudStorageResult, telegramResult);

  return (
      <Provider store={reduxStore}>
          <App />
      </Provider>
  );
};

const root = createRoot(document.getElementById("root"));
root.render(<Root />);

