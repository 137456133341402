// components/BasketIcon/BasketIcon.jsx
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import classes from './BasketIcon.module.css';
import { useIcons } from "../icons/Icons";
import { showBasket, hideBasket } from "../../store/actions/basketActions";
const BasketIcon = () => {
    const dispatch = useDispatch();
    const icons = useIcons();
    const showBasketState = useSelector(state => state?.basket?.showBasket);
    const basketItemCount = useSelector(state => state?.basket?.items?.reduce((count, item) => count + item.quantity, 0));
    const handleBasketClick = () => {
        if (!showBasketState) {
            dispatch(showBasket());
        }
      };
    return (
        <div className={classes.iconContainer}>
            <img
                src={icons.find(icon => icon.name === 'basket').image}
                alt="Basket"
                className={classes.basketIcon}
                onClick={handleBasketClick}
            />
             {basketItemCount > 0 && (
                <span className={classes.itemCount}>{basketItemCount}</span>
            )}
        </div>
    );
};

export default BasketIcon;
