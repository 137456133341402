// middleware/index.js
import appMiddleware from "./appMiddleware";
import catalogMiddleware from "./catalogMiddleware";
import filterMiddleware from "./filterMiddleware";
import goodMiddleware from "./goodMiddleware";
import basketMiddleware from "./basketMiddleware";
import favoriteMiddleware from "./favoriteMiddleware";
const combinedMiddleware = (cloudStorageResult, telegramResult) => {
    const middlewareList = [
        appMiddleware,
        catalogMiddleware,
        favoriteMiddleware,
        filterMiddleware,
        goodMiddleware,
        basketMiddleware,
    ];
    return store => next => action => {
        console.log(action?.type);
        //const invokeMiddleware = (middleware) => middleware(cloudStorageResult, telegramResult)(store)(next)(action);
        middlewareList.forEach(middleware => {
            middleware(cloudStorageResult, telegramResult)(store)(next)(action);
        });
        //invokeMiddleware(appMiddleware);
        //invokeMiddleware(catalogMiddleware);
        //invokeMiddleware(filterMiddleware);
        //invokeMiddleware(goodMiddleware);
        //invokeMiddleware(basketMiddleware);
        //appMiddleware(cloudStorageResult, telegramResult)(store)(next)(action);
        //catalogMiddleware(cloudStorageResult, telegramResult)(store)(next)(action);
        //filterMiddleware(cloudStorageResult, telegramResult)(store)(next)(action);
        //goodMiddleware(cloudStorageResult, telegramResult)(store)(next)(action);
        //basketMiddleware(cloudStorageResult, telegramResult)(store)(next)(action);
        //checkDataValidityMiddleware(cloudStorageResult, telegramResult)(store)(next)(action);
        return next(action);
    };
};

export default combinedMiddleware;