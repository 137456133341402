export function useTelegram() {
  const webApp = window.Telegram.WebApp;
  const user = webApp.initDataUnsafe?.user;
  const lang = 'ru'
  return {
    webApp,
    user,
    lang
  };
}
