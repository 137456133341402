export const text = {
    'ru': {
        filter:{
            price: 'Цена',
            from: 'от',
            to: 'до',
            categories:{
                name: 'Категории',
            }
        },
        good:{
            color: 'Цвет',
            size: 'Размер',
            description: 'Описание',
        }
    }
};