// components/basket/Basket.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateBasketItem, removeFromBasket } from '../../store/actions/basketActions';
import classes from './Basket.module.css';
const Basket = () => {
    const dispatch = useDispatch();
    const basketItems = useSelector(state => state?.basket?.items);
    const totalSum = basketItems.reduce((sum, item) => sum + item.price * item.quantity, 0);

    const handleDecreaseQuantity = (id, quantity) => {
        if (quantity > 1) {
            dispatch(updateBasketItem(id, quantity - 1));
        } else {
            dispatch(removeFromBasket(id));
        }
    };

    const handleIncreaseQuantity = (id, quantity) => {
        dispatch(updateBasketItem(id, quantity + 1));
    };
    return (
        <div className={classes.basketContainer}>
            {basketItems.map(item => (
                <div key={item.id} className={classes.basketItem}>
                    <img src={item.photoUrls[0]} alt={item.name} className={classes.itemImage} />
                    <div className={classes.itemDetails}>
                        <div className={classes.itemName}>{item.name}</div>
                        <div className={classes.itemQuantityControl}>
                            <button 
                                className={classes.decrease} 
                                onClick={() => handleDecreaseQuantity(item.id, item.quantity)}
                            >
                                -
                            </button>
                            <span className={classes.quantity}>{item.quantity}</span>
                            <button 
                                className={classes.increase} 
                                onClick={() => handleIncreaseQuantity(item.id, item.quantity)}
                            >
                                +
                            </button>
                        </div>
                        {/*<div className={classes.itemQuantity}>Количество: {item.quantity}</div>*/}
                        <div className={classes.itemPrice}>{item.price} ₽ x {item.quantity} = {item.price * item.quantity} ₽</div>
                    </div>
                </div>
            ))}
            <div className={classes.total}>
                <div className={classes.totalLabel}>Итого:</div>
                <div className={classes.totalSum}>{totalSum} ₽</div>
            </div>
        </div>
    );
};

export default Basket;
