import basket_dark from '../../static/image/icons/basket-90-black.png';
import basket from '../../static/image/icons/basket-90-white.png';

import {useTelegram} from "../../hooks/useTelegram";

export function useIcons() {
    const {webApp} = useTelegram();
    const icons = [
        {
            id:0,
            name: 'basket',
            image: webApp.colorScheme === 'dark'? basket : basket_dark
        },
    ];
    return icons;
}