// favoriteMiddleware.js
import {UPDATE_FAVORITE} from '../actions/favoriteActions';
import { updateFavoriteThunk } from '../thunks/favoriteThunks';

const favoriteMiddleware = (useCloudStorageResult, useTelegramResult) => (store) => (next) => (action) => {
  if (action.type === UPDATE_FAVORITE) {
    const { id, isFavorite } = action.payload;
    if (!isFavorite) {
      store.dispatch(updateFavoriteThunk(id, true)); // Like
    } else {
      store.dispatch(updateFavoriteThunk(id, false)); // Unlike
    }
  }
  //return next(action);
};

export default favoriteMiddleware;
