export function useCloudStorage() {
    const webApp = window.Telegram.WebApp
    const getKeys = () => {
        webApp.CloudStorage.getKeys((e, value)=>{
            return value
        })
    }

    const removeAll = () => {
        webApp.CloudStorage.getKeys((e, value)=>{
            webApp.CloudStorage.removeItems(value)
        })
    }

    const setItem = (key, value) => {
        webApp.CloudStorage.setItem(key, value, (result) => {
            // Callback-функция вызывается после завершения операции
            if (result === null) {
                console.log(`Значение ${key} - ${value} успешно установлено в хранилище cloudStorage`);
            } else {
                console.error(`Ошибка при установке значения ${key} - ${value} в хранилище cloudStorage: ${result}`);
            }
        });
    }; 

    const getItem = (key, callback) => {
        webApp.CloudStorage.getItem(key, (error, value) => {
            if (error === null){
                if (value !== null) {
                    console.log(`Значение для ключа ${key}: ${value}`);
                    callback(value);
                } else {
                    console.error(`Значение для ключа ${key} не найдено`);
                    callback(null);
                }
            } else{
                console.error(`Ошибка: ${error}`);
                callback(null);
            }
        });
    };
    const getItemAsync = (key) => {
        return new Promise((resolve, reject) => {
            getItem(key, (value) => {
                resolve(value);
            });
        });
    };

    return {
        webApp,
        getKeys,
        setItem,
        getItem,
        getItemAsync,
        removeAll
    }
}