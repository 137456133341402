export const SET_FILTER_OPTION = 'SET_FILTER_OPTION';
export const FETCH_FILTERS =  'FETCH_FILTERS';
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS';
export const SET_PRICE_RANGE = 'SET_PRICE_RANGE';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const setPriceRange = (range) => ({
    type: SET_PRICE_RANGE,
    payload: range,
  });
export const setFilterOption = (filterId, options) => ({
    type: SET_FILTER_OPTION,
    payload: { filterId, options }
});
export const fetchFilters = () => ({
    type: FETCH_FILTERS,
});
export const fetchFiltersSuccess = (categories) => ({
    type: FETCH_FILTERS_SUCCESS,
    payload: {
        categories: categories,
    },
});
export const setSearchText = (text) => ({
    type: SET_SEARCH_TEXT,
    payload: text,
});

export const SET_SECTION_CATEGORIES = 'SET_SECTION_CATEGORIES';

export const setSectionCategories = (categories) => ({
    type: SET_SECTION_CATEGORIES,
    payload: categories,
});