export const INIT_CATALOG = 'INIT_CATALOG';
export const FETCH_CATALOG_DATA = 'FETCH_CATALOG_DATA'; 
export const FINISH_FETCHING_CATALOG_DATA = 'FINISH_FETCHING_CATALOG_DATA';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const SHOW_FAVORITES = 'SHOW_FAVORITES';
export const initCatalog = () => ({
    type: INIT_CATALOG
  });
export const fetchCatalogData = () => ({
    type: FETCH_CATALOG_DATA
});
export const finishFetchingCatalogData = (catalogCards) => ({
    type: FINISH_FETCHING_CATALOG_DATA,
    payload: catalogCards,
});
export const applyFilters = (filteredCards) => ({
    type: APPLY_FILTERS,
    payload: filteredCards
  });
export const showFavorites = (flag, favorites) => ({
    type: SHOW_FAVORITES,
    payload: { flag, favorites},
});