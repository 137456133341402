import { combineReducers } from "redux";
import appReducer  from "./appReducer";
import catalogReducer from "./catalogReducer";
import filterReducer from "./filterReducer";
import goodReducer from "./goodReducer";
import basketReducer from "./basketReducer";
import errorReducer from "./errorReducer";
import favoriteReducer from "./favoriteReducer";
const hash = window.location.hash.slice(1);
const tgWebAppPlatformRegex = /tgWebAppPlatform=([^&]*)/;
const matchTgWebAppPlatform = hash.match(tgWebAppPlatformRegex);

if (matchTgWebAppPlatform) {
  const tgWebAppPlatform = matchTgWebAppPlatform[1];
}

const rootReducer = combineReducers({
    app: appReducer,
    catalog: catalogReducer,
    filter: filterReducer,
    good: goodReducer,
    basket: basketReducer,
    error: errorReducer,
    favorite: favoriteReducer,
  });
  
  export default rootReducer;