import $api from '../../http';
import {bot_url_with_param} from '../../http/urls';
import {setSelectedGood} from '../actions/appActions';
import { goodLoaded } from '../actions/goodActions';


export const getGoodData = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const id = state?.app?.selectedGood;
        if(id){
            const response = await $api.get(`/click/${id}`);
            if(response.status === 200){
                dispatch(goodLoaded(response.data));
            }
        }
    }catch (error) {
        console.error('Error fetching good data:', error);
    }
};
export const getGoodDataByLink = (link) => async (dispatch) => {
    try {
        const response = await $api.get(`/resolveGoodLink/${link}`);
        const goodId = response.data.id;
        dispatch(setSelectedGood(goodId));
    } catch (error) {
        console.error("Failed to fetch good by link", error);
    }
};
export const shareGood = async (link, name) => {
    const urlWithParams = `${bot_url_with_param}${link}`;
    const shareText = `Смотри, что я нашел! ${name}`;

    const encodedShareText = encodeURIComponent(shareText);
    window.open(`https://t.me/share/url?url=${urlWithParams}&text=${encodedShareText}`);
  };