import { INIT_APP, 
    START_PARAM_DETECTED,
    CHECK_START_PARAM,
    AUTHENTIFICATED, 
    FINISH_LOADING,
    ERROR_LOADING,
    BIND_BUTTONS, BIND_MAIN_BTN, BIND_BACK_BTN,
    SAVE_END_INDEX,
    SAVE_SCROLL_TOP,
    SET_SELECTED_GOOD,
    HIDE_GOOD,
    SAVE_USER_INFO, UPDATE_USER_INFO,
    SET_SELECTED_SECTION
} from "../actions/appActions";


const initialState = {
    startParam: null,
    loading: true,
    email: null,
    avatarUrl: null,
    firstName: null,
    lastName: null,
    surname: null,
    error: null,
    endIndex: 12,
    scrollTop: 0,
    selectedGood: -1,
    selectedSection: null
}
const appReducer = (state = initialState, action) => {
    switch(action?.type){
        case INIT_APP:
            return{
                ...state,
            };
        case START_PARAM_DETECTED:
            return {
                ...state,
                startParam: action.payload,
            };
        case CHECK_START_PARAM:
            return {
                ...state,
            }
        case AUTHENTIFICATED: 
            return{
                ...state,
            }
        case FINISH_LOADING:
            return{
                ...state,
                loading: false,
            }
        case ERROR_LOADING:
            return{
                ...state,
                error: action.payload,
            }
        case BIND_BUTTONS:
            return{
                ...state,
            };
        case BIND_MAIN_BTN:
            return{
                ...state,
            };
        case BIND_BACK_BTN:
            return{
                ...state,
            };
        case SAVE_END_INDEX:
            return {
                ...state,
                endIndex: action.payload
            };
        case SAVE_SCROLL_TOP:
            return {
                ...state,
                scrollTop: action.payload
            };
        case SAVE_USER_INFO:
            const {email, role, avatarUrl, firstName, lastName, surname} = action.payload;
            return{
                ...state,
                email: email,
                role: role,
                avatarUrl: avatarUrl,
                firstName: firstName,
                lastName: lastName,
                surname: surname
            };
        case UPDATE_USER_INFO:
            const { newFirstName, newLastName, newSurname, newCompany} = action.payload;
            return{
                ...state,
                firstName: newFirstName,
                lastName: newLastName,
                surname: newSurname,
            };
        case SET_SELECTED_GOOD:
            return {
                ...state,
                selectedGood: action.payload,
            }
        case HIDE_GOOD:
            return{
                ...state,
                selectedGood: action.payload,
            }
        case SET_SELECTED_SECTION:
            return {
                ...state,
                selectedSection: action.payload,
            }
        default:
        return state;
    }
}
export default appReducer;