import $api from '../../http';
import {authenticateWithInitData} from '../../http/telegramAuth';
import { saveUserInfo, bindButtons, bindMainBtn, bindBackBtn, authenticated, finishLoading, errorLoading, setSelectedSection } from '../actions/appActions';

export const bindBtns = () => async (dispatch) => {
    dispatch(bindButtons());
    dispatch(bindMainBtn());
    dispatch(bindBackBtn());
}
export const telegramAuth = (useCloudStorageResult, useTelegramResult) => async (dispatch) => {
    const { setItem, getItemAsync } = useCloudStorageResult;  
    const { webApp, lang, user } = useTelegramResult;
    try{
        const {accessToken, refreshToken }= await authenticateWithInitData(window.Telegram.WebApp.initData);
        console.log($api.defaults.baseURL);
        if (accessToken) {
        $api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        await setItem('accessToken', accessToken);
        await setItem('refreshToken', refreshToken);
        const responseCheck = await $api.get('/check-verification');    
        const data = responseCheck.data;
        if (data?.isVerified) {
            await setItem('contact', true);
            dispatch(authenticated());
            dispatch(setSelectedSection(null));
        }else {
            try {
                webApp.requestContact(async (bool, result) => {
                    console.log(result);
                    const contact = result?.responseUnsafe?.contact;
                    if (bool) {
                        await setItem('contact', true);
                        const { phone_number, user_id } = contact;
                        const payload = {
                            clientId: user_id,
                            phoneNumber: phone_number
                        };
                        $api.post('/verify-user', payload)
                            .then((responseVerify) => {
                                const verifyData = responseVerify.data;
                                if (verifyData.success) {
                                    dispatch(authenticated());
                                } else {
                                    dispatch(errorLoading('Verification failed'));
                                    return;
                                }
                            })
                            .catch((error) => {
                                console.error('Verification error:', error);
                                dispatch(errorLoading('Verification failed'));
                                return;
                            });
                    } else {
                        await setItem('contact', false);
                        webApp.showPopup({
                            message: 'Вам необходимо будет связаться самостоятельно с администратором чтобы мы начали обрабатывать ваш заказ.',
                        });
                        dispatch(authenticated());
                        dispatch(setSelectedSection(null));
                        return;
                    }
                });
                
            } catch (contactError) {
                console.error('Contact request error:', contactError);
                dispatch(errorLoading('Contact request denied'));
                return;
            }
            webApp.requestWriteAccess((bool) => {
                if (!bool) {
                    dispatch(errorLoading('Write access request denied'));
                    return;
                }
            });
        }
    }
    }catch (error) {
        console.error('Authentication error:', error);
        dispatch(errorLoading('Authentication error'));
        return;
    }
}
export const appClick = () => async (dispatch) => {
    const response = await $api.post('/appClick');
    if(response.status === 200){
        dispatch(finishLoading());
    }
    else{
        dispatch(errorLoading(response));
    }
    
}