import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classes from "./Good.module.css";
import Favorite from '../favorite/Favorite';
import ImageSlider from '../imageSlider/ImageSlider';
import ShareButton from '../shareButton/ShareButton';
import {text} from "../../translate";
import {useTelegram} from "../../hooks/useTelegram";
import {initGood} from "../../store/actions/goodActions";
import BasketBtn from '../basketBtn/BasketBtn';

const Good = () => {
    const dispatch = useDispatch();
    const {lang, webApp} = useTelegram();
    const item = useSelector(state => state.good?.item);
    const loading = useSelector(state => state.good?.loading);
    
    useEffect(() => {
        dispatch(initGood());
      }, []);

    return (
        <div className={`${classes.container} ${!loading ? classes.visible : ''}`}>
        {!loading && item && (
            <div className={classes._}>
                <div className={classes.block1}>
                    <ImageSlider photos={item?.photoUrls} />
                    <div className={classes.infoBlock}>
                        <div className={classes.name}>{item?.name}</div>
                        <div className={classes.block1_2}>
                            <div className={classes.price}>{item?.price} ₽</div>
                        </div>
                        <ShareButton link={item?.link} name={item?.name} />
                        <Favorite id={item?.id} />
                    </div>
                </div>
                <div className={classes.block1_5}>
                    <div className={classes.block1_5_1}>
                        <div className={classes.infoText5}>
                            {item?.color ? item.color : '---'}
                        </div>
                        <div className={classes.infoText6}>
                            {text[lang].good.color}
                        </div>
                    </div>
                    <div className={classes.block1_5_1}>
                        <div className={classes.infoText5}>
                            {item?.size ? item.size : '---'}
                        </div>
                        <div className={classes.infoText6}>
                            {text[lang].good.size}
                        </div>
                    </div>
                </div>
                <div className={classes.block1_5}>
                        <BasketBtn item={item} />
                </div>
                <div className={classes.block3}>
                    <div className={classes.infoText3}>{text[lang].good.description}</div>
                    <div className={classes.infoText4} dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                </div>
            </div>
        )}
    </div>
    );
};

export default Good;