import {SET_PRICE_RANGE, SET_FILTER_OPTION,
    FETCH_FILTERS,FETCH_FILTERS_SUCCESS, SET_SEARCH_TEXT, SET_SECTION_CATEGORIES} from '../actions/filterActions';

const initialState = {
    loading: false,
    error: null,
    priceRange: {from: null, to: null},
    categories: [],
    sectionCategories: [],
    selectedOptions: {},
    searchText: '',
  };

  const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FILTERS:
            return {
                ...state,
            };
        case FETCH_FILTERS_SUCCESS:
            const {categories} = action.payload;
        return {
            ...state,
            categories: categories,
        };
        case SET_SECTION_CATEGORIES:
            const filteredCategories = state.categories.filter(category => 
                action.payload.includes(category.name)
            );
            return {
                ...state,
                sectionCategories: filteredCategories,
            };
        case SET_FILTER_OPTION:
            const { filterId, options } = action.payload;
            return {
                ...state,
                selectedOptions: {
                    ...state.selectedOptions,
                    [filterId]: options
                }
            };
        case SET_PRICE_RANGE:
            return { 
                ...state,
                priceRange: action.payload
            };
        case SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload
            };
        default:
        return state;
    }
  };
  
  export default filterReducer;