import { fetchFiltersData, filtrate } from '../thunks/filterThunks';
import { FETCH_FILTERS, SET_FILTER_OPTION, SET_PRICE_RANGE, SET_SEARCH_TEXT } from '../actions/filterActions';
const filterMiddleware = (useCloudStorageResult, useTelegramResult) => (store) => (next) => (action) => {
  const { dispatch, getState } = store;
  const result = next(action);

  switch (action.type){
    case FETCH_FILTERS:
      dispatch(fetchFiltersData(useCloudStorageResult, useTelegramResult));
      break;
    case SET_FILTER_OPTION:
      dispatch(filtrate(useCloudStorageResult, useTelegramResult));
      break;
    case SET_PRICE_RANGE:
      dispatch(filtrate(useCloudStorageResult, useTelegramResult));
      break;
    case SET_SEARCH_TEXT: 
      dispatch(filtrate(useCloudStorageResult, useTelegramResult));
      break;
    default:break;
  }
  //return next(action);
};

export default filterMiddleware;