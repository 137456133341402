import "./App.css";
import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { initApp } from "./store/actions/appActions";
import Loading from "./components/loading/Loading";
import Catalog from "./components/catalog/Catalog";
import Filter from "./components/filter/Filter";
import Good from "./components/good/Good";
import Basket from "./components/basket/Basket"; 
import BasketIcon from "./components/basketIcon/BasketIcon";
import ErrorComponent from "./components/error/Error";
import FavoriteIcon from "./components/favoriteIcon/FavoriteIcon";
import Sections from "./components/sections/Sections";

const App = () =>  {
  const AppRef = useRef(null);

  const dispatch = useDispatch();

  const loading = useSelector(state => state?.catalog?.loading);
  const selectedGood = useSelector(state => state?.app?.selectedGood);
  const showBasketState = useSelector(state => state?.basket?.showBasket);
  const isError = useSelector(state => state?.error?.isError);
  const selectedSection = useSelector(state => state?.app?.selectedSection);

  useEffect(() => {
    dispatch(initApp());
  }, []);

  if (isError) {
    return <ErrorComponent />;
}

  return (
        <div className="App" ref={AppRef}>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <div className="top-bar">
                        {selectedGood === -1 && !showBasketState && <FavoriteIcon />}
                        <BasketIcon/>
                    </div>
                    {showBasketState  ? (
                        <Basket />
                    ) : selectedGood !== -1 && selectedGood ? (
                        <Good />
                    ) : (
                        <>
                            {selectedSection === null && <Sections />}
                            {selectedSection && ( 
                                <>
                                    <Filter />
                                    <Catalog appRef={AppRef} />
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default App;