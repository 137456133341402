import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './FavoriteIcon.module.css';
import { showFavorites } from '../../store/actions/catalogActions'; 

const FavoriteIcon = () => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const favorites = useSelector(state => state?.favorite?.goods);
  const handleClick = () => {
    setIsActive(!isActive);
    dispatch(showFavorites(!isActive, favorites)); 
  };

  return (
    <>
      {isActive ? (
        <svg
          className={classes.like}
          onClick={handleClick}
          opacity={1}
          width="30"
          height="28"
          viewBox="0 2.25 26 24"
          fill="#c20000" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 .587l3.668 7.425L24 9.432l-6 5.855 1.418 8.283L12 18.567 4.582 23.57 6 15.287 0 9.432l8.332-1.42L12 .587z" />
        </svg>
      ) : (
        <svg
          className={classes.like}
          onClick={handleClick}
          opacity={1}
          width="30"
          height="28"
          viewBox="0 2.25 26 24"
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 .587l3.668 7.425L24 9.432l-6 5.855 1.418 8.283L12 18.567 4.582 23.57 6 15.287 0 9.432l8.332-1.42L12 .587zm0 1.933l-3.012 6.086-6.706.974 4.855 4.737L6.314 21.4l5.686-2.991 5.686 2.991-1.299-7.083 4.855-4.737-6.706-.974L12 2.52z" fill="#c20000" />
        </svg>
      )}
    </>
  );
};

export default FavoriteIcon;
