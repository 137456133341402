import { 
    FINISH_FETCHING_CATALOG_DATA,
    FETCH_CATALOG_DATA,
    INIT_CATALOG, 
    fetchCatalogData 
} from "../actions/catalogActions";
import { catalogData } from "../thunks/catalogThunks";

const catalogMiddleware = (useCloudStorageResult, useTelegramResult) => (store) => (next) => (action) => {
    const { dispatch, getState } = store;
    const { setItem, getItemAsync } = useCloudStorageResult;  
    const { webApp, lang } = useTelegramResult;
    switch(action?.type){
        case INIT_CATALOG:
            dispatch(fetchCatalogData());
            break;
        case FETCH_CATALOG_DATA:
            dispatch(catalogData(useCloudStorageResult, useTelegramResult));
            break;
        case FINISH_FETCHING_CATALOG_DATA:

            break;
        default:
            break;
    }
    //return next(action);
}
export default catalogMiddleware;