// Loading.jsx
import React from 'react';
import classes from './Loading.module.css';
import LoadingSVG from '../../static/gif/helmet.gif';

const Loading = () => {
  return (
    <div className={classes.loading_container}>
      <img src={LoadingSVG} alt="icon" />
    </div>
  );
};

export default Loading;