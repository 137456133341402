import $api from '../../http';
import { text } from '../../translate';
import {fetchFiltersSuccess} from '../actions/filterActions';
import {applyFilters} from '../actions/catalogActions';

export const filtrate = (useCloudStorageResult, useTelegramResult) => {
    const {lang} = useTelegramResult;
    const { setItem, getItemAsync } = useCloudStorageResult;

    return async (dispatch, getState) => {
        try {
            const state = getState();
            const options = state?.filter?.selectedOptions;
            const data = state?.catalog?.sectionCards;
            const priceRange = state?.filter?.priceRange;
            const searchText = state?.filter?.searchText;
            if (data && data.length > 0){
                const filtered = filterData(data, options, priceRange, searchText);
                dispatch(applyFilters(filtered));
            } 
        }catch (error) {
            console.error('Error fetching filters data:', error);
        }
    };
};
const filterData = (data, selectedOptions, priceRange, searchText) => {
    // Преобразуем selectedOptions в удобный для поиска объект
    const filters = Object.keys(selectedOptions).reduce((acc, filterId) => {
      acc[filterId] = selectedOptions[filterId].map(option => option.name);
      return acc;
    }, {});
    // Фильтрация данных
    const hasFilters = Object.keys(filters).some(filterId => filters[filterId].length > 0);
    return data.filter(item => {
      // Проверяем, есть ли выбранные фильтры
      // Если фильтры не установлены, показываем все данные
      if (!hasFilters && (!priceRange.from && !priceRange.to) && !searchText) {
        return true;
      }
      // Проверяем соответствие по каждому фильтру
      const filterMatches = Object.entries(filters).every(([filterId, filterValues]) => {
        switch (parseInt(filterId, 10)) {
          case 1: // categories
            return filterValues.includes(item.categoryName);
          default:
            return true;
        }
      });

      const priceMatches = (!priceRange.from || item.price >= priceRange.from) &&
                             (!priceRange.to || item.price <= priceRange.to);
      
      const searchMatches = item.name.toLowerCase().includes(searchText.toLowerCase());

      if(!hasFilters && !filterMatches){
        return priceMatches && searchMatches;
      }
      return filterMatches && priceMatches && searchMatches;                       
                             
    });
  };
export const fetchFiltersData = (useCloudStorageResult, useTelegramResult) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const { setItem, getItemAsync } = useCloudStorageResult;  
            const { webApp, lang } = useTelegramResult;
            const response = await $api.get('/categories');
            dispatch(fetchFiltersSuccess(response.data));

        }catch (error) {
            console.error('Error fetching filters data:', error);
        }
    };
};