import React from 'react';
import { useDispatch } from 'react-redux';
import { setSearchText } from '../../../store/actions/filterActions';
import classes from './SearchInput.module.css';

const SearchInput = () => {
    const dispatch = useDispatch();

    const handleSearchChange = (event) => {
        dispatch(setSearchText(event.target.value));
    };

    return (
        <div className={classes.searchContainer}>
            <input
                type="text"
                placeholder="Поиск по названию"
                className={classes.searchInput}
                onChange={handleSearchChange}
            />
        </div>
    );
};

export default SearchInput;
