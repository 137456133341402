export const SET_FAVORITES = 'SET_FAVORITES';
export const UPDATE_FAVORITE = 'UPDATE_FAVORITE';
export const SET_FAVORITE = 'SET_FAVORITE';

export const setFavorites = (goods) => ({
    type: SET_FAVORITES,
    payload: goods,
  });
export const updateFavorite = (id, isFavorite) => ({
    type: UPDATE_FAVORITE,
    payload: { id, isFavorite },
});
export const setFavorite = (id, isFavorite) => ({
    type: SET_FAVORITE,
    payload: { id, isFavorite },
});