import React from 'react';
import classes from './Error.module.css';
import skull from '../../static/gif/skull.gif';
const ErrorComponent = () => {
  return (
    <div className={classes.errorOverlay}>
      <div className={classes.errorContent}>
        <img src={skull} alt="icon" />
        <p className={classes.errorMessage}>
          Без подтверждения номера продолжить не получится.<br />
          Пожалуйста, перезагрузите и попробуйте еще раз.
        </p>
      </div>
    </div>
  );
};

export default ErrorComponent;
