import { getGoodData } from '../thunks/goodThunks';
import { INIT_GOOD, GOOD_LOADED } from '../actions/goodActions';
const goodMiddleware = (useCloudStorageResult, useTelegramResult) => (store) => (next) => (action) => {
  const { dispatch, getState } = store;
  const result = next(action);

  switch (action.type){
    case INIT_GOOD:
      dispatch(getGoodData());
      break;
    case GOOD_LOADED:
      break;
    default:break;
  }
  //return next(action);
};

export default goodMiddleware;