import React from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedSection } from '../../store/actions/appActions';
import { setSectionCategories } from '../../store/actions/filterActions';
import classes from './Sections.module.css';
import defenceWhite from '../../images/png/icons8-bulletproof-vest-100 white.png';
import defenceBlack from '../../images/png/icons8-bulletproof-vest-100 black.png';
import beltWhite from '../../images/png/icons8-championship-belt-100 white.png';
import beltBlack from '../../images/png/icons8-championship-belt-100 black.png';
import medicineWhite from '../../images/png/icons8-doctors-bag-100 white.png';
import medicineBlack from '../../images/png/icons8-doctors-bag-100 black.png';
import bagWhite from '../../images/png/icons8-expedition-backpack-100 white.png';
import bagBlack from '../../images/png/icons8-expedition-backpack-100 black.png';
import razgruzWhite from '../../images/png/icons8-life-jacket-100 white.png';
import razgruzBlack from '../../images/png/icons8-life-jacket-100 black.png';
import uniformWhite from '../../images/png/icons8-military-uniform-100 white.png';
import uniformBlack from '../../images/png/icons8-military-uniform-100 black.png';
import rifleWhite from '../../images/png/icons8-rifle-magazine-100 white.png';
import rifleBlack from '../../images/png/icons8-rifle-magazine-100 black.png';
import headWhite from '../../images/png/icons8-stormtrooper-100 white.png';
import headBlack from '../../images/png/icons8-stormtrooper-100 black.png';

const sections = [
    {   
        name: 'Одежда', 
        categories: ['Арафатка', 'Комбинезон', 'Майка', 'Термобрюки', 'Термокофта'], 
        icons:{black: uniformBlack, white: uniformWhite} 
    },
    { 
        name: 'Головные уборы', 
        categories: ['Балаклава', 'Бейсболка', 'Панама', 'Шапка'], 
        icons:{black: headBlack, white: headWhite} 
    },
    { 
        name: 'Защита', 
        categories: ['Бронежилет', 'Защита колена', 'Защита корпуса', 'Маска для страйкбола'], 
        icons:{black: defenceBlack, white: defenceWhite} 
    },
    { 
        name: 'Медицина', 
        categories: ['Бинт медицинский', 'Жгут кровоостанавливающий', 'Набор для выживания'], 
        icons:{black: medicineBlack, white: medicineWhite} 
    },
    { 
        name: 'Подсумки', 
        categories: ['Подсумок', 'Подсумок для аптечки', 'Подсумок для гранаты'], 
        icons:{black: rifleBlack, white: rifleWhite} 
    },
    { 
        name: 'Разгрузка', 
        categories: ['Разгрузочная система', 'Пояс разгрузочный'], 
        icons:{black: razgruzBlack, white: razgruzWhite} 
    },
    { 
        name: 'Ремни', 
        categories: ['Ремень', 'Ремень тактический'], 
        icons:{black: beltBlack, white: beltWhite} 
    },
    { 
        name: 'Рюкзаки', 
        categories: ['Рюкзак', 'Сумка тактическая', 'Сумка для охоты'], 
        icons:{black: bagBlack, white: bagWhite} 
    },
];

const Sections = () => {
    const dispatch = useDispatch();

    const handleSectionClick = (section) => {
        dispatch(setSelectedSection(section));
        dispatch(setSectionCategories(section.categories));
    };

    return (
        <div className={classes.sectionsContainer}>
            {sections.map((section) => (
                <div key={section.name} className={classes.sectionTile} onClick={() => handleSectionClick(section)}>
                    <div className={classes.sectionTileName}>{section.name}</div>
                    <img className={classes.sectionTileImage} src={section.icons.black}/>
                </div>
            ))}
        </div>
    );
};

export default Sections;
