// middleware/basketMiddleware.js
import { INIT_BASKET, ADD_TO_BASKET, REMOVE_FROM_BASKET, UPDATE_BASKET_ITEM, SHOW_BASKET, HIDE_BASKET,
    SUBMIT_ORDER_REQUEST, SUBMIT_ORDER_SUCCESS, SUBMIT_ORDER_FAILURE, clearBasket 
} from '../actions/basketActions';
import {submitOrder} from '../thunks/basketThunks';
const basketMiddleware = (useCloudStorageResult, useTelegramResult) => (store) => (next) => (action) => {
    const { webApp, lang } = useTelegramResult;
    const state = store.getState();
    const basketItems = state?.basket?.items;
    switch (action?.type) {
        case INIT_BASKET:
            webApp.MainButton.setText("Оформить заказ");
            webApp.MainButton.onClick(() => {
                store.dispatch(submitOrder(useCloudStorageResult, useTelegramResult));
            });
            break;
        case ADD_TO_BASKET:
            if (basketItems.length > 0) { 
                webApp.MainButton.show();
            } else {
                webApp.MainButton.hide();
            }
            break;
        case REMOVE_FROM_BASKET:
            if (basketItems.length > 0) { 
                webApp.MainButton.show();
            } else {
                webApp.MainButton.hide();
            }
            break;
        case UPDATE_BASKET_ITEM:
            if (basketItems.length > 0) { 
                webApp.MainButton.show();
            } else {
                webApp.MainButton.hide();
            }
            break;
        case SHOW_BASKET:
            webApp.BackButton.show();
            if (basketItems.length > 0) { 
                webApp.MainButton.show();
            } else {
                webApp.MainButton.hide();
            }
            break;
        case HIDE_BASKET:
            break;
        case SUBMIT_ORDER_SUCCESS:
            webApp.MainButton.hide();
            store.dispatch(clearBasket()); 
            break;
        case SUBMIT_ORDER_FAILURE:
            webApp.MainButton.hide();
            break;
        default:
            break;
    }
    //return next(action);
};
export default basketMiddleware;
