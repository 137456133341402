//appMiddleware.jsx
import { 
    START_PARAM_DETECTED,
    CHECK_START_PARAM,
    INIT_APP,
    AUTHENTIFICATED, 
    ERROR_LOADING,
    BIND_BUTTONS, BIND_MAIN_BTN, BIND_BACK_BTN, 
    UPDATE_USER_INFO,
    FINISH_LOADING,
    SAVE_END_INDEX, 
    SAVE_SCROLL_TOP,
    SET_SELECTED_GOOD,
    SET_SELECTED_SECTION
} from "../actions/appActions";
import { appClick, bindBtns, telegramAuth } from "../thunks/appThunks";
import {getGoodDataByLink} from '../thunks/goodThunks';
import {initCatalog} from "../actions/catalogActions";
import { fetchFilters } from "../actions/filterActions";
import {hideGood, checkStartParam, setSelectedSection} from "../actions/appActions";
import { hideBasket, initBasket } from "../actions/basketActions";
import { errorLoading, errorOff } from "../actions/errorActions";
import { fetchFavorites } from "../thunks/favoriteThunks";

const appMiddleware = (useCloudStorageResult, useTelegramResult)=> (store) => (next) => (action) => {
    let backBtnClickHandler = null;
    const { dispatch, getState } = store;
    const { setItem, getItemAsync } = useCloudStorageResult;  
    const { webApp, lang } = useTelegramResult;
    const state = getState();
    const backBtnClick = () => {
        const st = getState();
        const section = st?.app?.selectedSection;
        const goodId = st?.app?.selectedGood;
        const basketVisibility = st?.basket?.showBasket;
        if(goodId < 0 && !basketVisibility && section !== null){
            webApp.BackButton.hide();
            dispatch(setSelectedSection(null));
            return;
        }
        if(goodId > 0 && !basketVisibility){
            dispatch(hideGood());       
            return;  
        }
        if(goodId > 0 && basketVisibility){
            dispatch(hideBasket());
            return;
        }
        if(basketVisibility && section !== null){
            dispatch(hideBasket());
            return;
        }
        if(basketVisibility && section === null){
            webApp.BackButton.hide();
            dispatch(hideBasket());
            return;
        }
    };

    const handleBackBtnClick = () => backBtnClickHandler && backBtnClickHandler();

    backBtnClickHandler = backBtnClick;

    switch(action?.type){
        case INIT_APP:
            webApp.ready();
            dispatch(telegramAuth(useCloudStorageResult, useTelegramResult));
            webApp.expand();
            webApp.MainButton.hide();
            webApp.BackButton.hide();
            dispatch(bindBtns());    


            const hash = window.location.hash.slice(1);
            const params = new URLSearchParams(hash);
            const tgWebAppData = new URLSearchParams(params.get('tgWebAppData'));
            const startParam = tgWebAppData.get('start_param');
            console.log(startParam);
            if (startParam) {
                dispatch({ type: START_PARAM_DETECTED, payload: startParam });
            }
            break;
        case CHECK_START_PARAM:
            if (state?.app?.startParam?.startsWith('good_')) {
                dispatch(getGoodDataByLink(state?.app?.startParam));
            }
            break;
        case AUTHENTIFICATED:
            dispatch(appClick());
            dispatch(errorOff());
            break;
        case FINISH_LOADING:
            dispatch(initCatalog());
            dispatch(fetchFavorites());
            dispatch(fetchFilters());
            dispatch(checkStartParam());
            break;
        case ERROR_LOADING: 
            dispatch(errorLoading())
            break;
        case BIND_BUTTONS:
            break;
        case BIND_MAIN_BTN:
            dispatch(initBasket());
            break;
        case BIND_BACK_BTN:
            webApp.BackButton.onClick(handleBackBtnClick);
            break;
        case SAVE_END_INDEX:
            // Обновляем значение endIndex в хранилище и в CloudStorage
            setItem('endIndex', action.payload);
            break;
        case SAVE_SCROLL_TOP:
            // Обновляем значение scrollTop в хранилище и в CloudStorage
            setItem('scrollTop', action.payload);
            break;
        case SET_SELECTED_SECTION:
            if(action.payload !== null){
                webApp.BackButton.show();
            }
            break;
        case SET_SELECTED_GOOD:
            webApp.BackButton.show();
            break;
        case UPDATE_USER_INFO:
            //dispatch(updatePersonalInfo(useCloudStorageResult, useTelegramResult, action?.payload));
            break;
        default:
            break;
    }
    //return next(action);
}
export default appMiddleware;