import $api from '../../http';
import {setFavorite, setFavorites} from '../actions/favoriteActions';

// Action Creator для переключения избранного
export const updateFavoriteThunk = (goodId, isFavorite) => async (dispatch) => {
  try {
    // Определяем URL для добавления или удаления избранного
    const url = `/favorites`;
    console.log(isFavorite);
    console.log(goodId);
    // Отправляем соответствующий HTTP-запрос
    if (isFavorite) {
      // Отправляем POST запрос для добавления в избранное
      await $api.post(url, {goodId});
    } else {
      const data = {
        goodId: goodId,
      };
      // Отправляем DELETE запрос для удаления из избранного
      await $api.delete(url, {data: data});
    }

    dispatch(setFavorite(goodId, isFavorite));
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
  }
};

export const fetchFavorites = () => async (dispatch) => {
  try {
    const response = await $api.get('/favorites');
    const favoriteGoods = response.data; 
    dispatch(setFavorites(favoriteGoods));
  } catch (error) {
    console.error('Ошибка при загрузке избранного:', error);
  }
};