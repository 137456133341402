import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from "./Favorite.module.css";
import { updateFavorite} from '../../store/actions/favoriteActions';

const Favorite = ({ id }) => {
  const dispatch = useDispatch();
  const isFavorite = useSelector((state) => state?.favorite?.goods?.includes(id));
  const pressLike = () => {
    dispatch(updateFavorite(id, isFavorite));
  };

  return (
    <>
      {isFavorite ? (
        <svg
          className={classes.like}
          onClick={(e) => {
            e.stopPropagation();
            pressLike();
          }}
          opacity={1}
          width="26"
          height="24"
          viewBox="0 0 26 24"
          fill="#c20000"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 .587l3.668 7.425L24 9.432l-6 5.855 1.418 8.283L12 18.567 4.582 23.57 6 15.287 0 9.432l8.332-1.42L12 .587z" />
        </svg>
      ) : (
        <svg
          className={classes.like}
          onClick={(e) => {
            e.stopPropagation();
            pressLike();
          }}
          opacity={1}
          width="26"
          height="24"
          viewBox="0 0 26 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
         <path d="M12 .587l3.668 7.425L24 9.432l-6 5.855 1.418 8.283L12 18.567 4.582 23.57 6 15.287 0 9.432l8.332-1.42L12 .587zm0 1.933l-3.012 6.086-6.706.974 4.855 4.737L6.314 21.4l5.686-2.991 5.686 2.991-1.299-7.083 4.855-4.737-6.706-.974L12 2.52z" fill="#c20000" />
        </svg>
      )}
    </>
  );
};

export default Favorite;