import React, { useState, useEffect } from 'react';
import classes from "./ImageCard.module.css";
//import { media_api } from "../../store/thunks/urls";
import imagePlaceholder from '../../static/image/card/placeholder.jpg';

const ImageCard = ({ photo }) => {
    const [imagePath, setImagePath] = useState(imagePlaceholder);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (photo) {
            const img = new Image();
            img.src = photo;
            img.onload = () => {
                setImagePath(photo);
                setIsLoading(false);
            };
            img.onerror = () => {
                setIsLoading(false);
            };
        } else {
            setIsLoading(false);
        }
    }, [photo]);



    return (
        <div className={classes.image_box}>
        <img className={classes.image} src={isLoading ? imagePlaceholder : imagePath} alt="unit" />
    </div>
    );
};

export default ImageCard;