// reducers/basketReducer.js
import { INIT_BASKET, ADD_TO_BASKET, REMOVE_FROM_BASKET, UPDATE_BASKET_ITEM, SHOW_BASKET, HIDE_BASKET,
    SUBMIT_ORDER_REQUEST, SUBMIT_ORDER_SUCCESS, SUBMIT_ORDER_FAILURE, CLEAR_BASKET 
} from '../actions/basketActions';

const initialState = {
    items: [],
    showBasket: false,
};

const basketReducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_BASKET:
            return{
                ...state
            };
        case ADD_TO_BASKET:
            console.log(action.payload);
            const existingItem = state.items.find(item => item?.id === action.payload.id);
            if (!existingItem) {
                return {
                    ...state,
                    items: [...state.items, { ...action.payload, quantity: 1 }],
                };
            }
        case UPDATE_BASKET_ITEM:
            console.log(action.payload);
            return {
                ...state,
                items: state.items.map(item =>
                    item?.id === action.payload.itemId
                        ? { ...item, quantity: action.payload.quantity }
                        : item
                ),
            };
        case REMOVE_FROM_BASKET:
            console.log(action.payload);
            return {
                ...state,
                items: state.items.filter(item => item?.id !== action.payload),
            };
        case SHOW_BASKET:
            return {
                ...state,
                showBasket: true
            };
        case HIDE_BASKET:
            return {
                ...state,
                showBasket: false
            };
        case SUBMIT_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case SUBMIT_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case SUBMIT_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case CLEAR_BASKET:
            return {
                ...state,
                items: [],
            };
        default:
            return state;
    }
};

export default basketReducer;
