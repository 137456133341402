import React, { useEffect, useState } from 'react';
import classes from "./Filter.module.css";
import { useSelector } from 'react-redux';
import Select from "./select/Select";
import {text} from "../../translate";
import {useTelegram} from "../../hooks/useTelegram";
import Range from "./range/Range";
import SearchInput from "./searchInput/SearchInput";

const Filter = () => {
  const {lang} = useTelegram();
  const { categories, priceRange, sectionCategories} = useSelector(state => state?.filter);
  return (
     <div className={classes._}>
          <SearchInput />
          <Select 
            id={1}
            name={text[lang].filter.categories.name} 
            options={sectionCategories || []} 
            multi={true}
          />
         <Range/>
    </div>
  );
};

export default Filter;