// components/basketBtn/BasketBtn.jsx
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addToBasket, removeFromBasket, updateBasketItem } from '../../store/actions/basketActions';
import classes from './BasketBtn.module.css';

const BasketBtn = ({ item }) => {
    const dispatch = useDispatch();
    const basketItem = useSelector(state => state.basket.items.find(i => i.id === item.id));

    const handleAddToBasket = () => {
        dispatch(addToBasket(item));
    };

    const handleRemoveFromBasket = () => {
        if (basketItem.quantity > 1) {
            dispatch(updateBasketItem(item.id, basketItem.quantity - 1));
        } else {
            dispatch(removeFromBasket(item.id));
        }
    };

    const handleIncreaseQuantity = () => {
        dispatch(updateBasketItem(item.id, basketItem.quantity + 1));
    };

    return (
        <div className={classes.container}>
            {!basketItem ? (
                <button className={classes.addToBasket} onClick={handleAddToBasket}>В корзину</button>
            ) : (
                <div className={classes.quantityControl}>
                    <button className={classes.decrease} onClick={handleRemoveFromBasket}>-</button>
                    <span className={classes.quantity}>{basketItem.quantity}</span>
                    <button className={classes.increase} onClick={handleIncreaseQuantity}>+</button>
                </div>
            )}
        </div>
    );
};

export default BasketBtn;
