import { 
    INIT_GOOD,
    GOOD_LOADED,
 } from "../actions/goodActions";

 const initialState = {
    item: {},
    loading: false,
    error: null
  };
  const goodReducer = (state = initialState, action) => {
    switch (action.type) {
      case INIT_GOOD:
        return {
          ...state,
          loading: true,
          error: null
        };
    case GOOD_LOADED:
        return{
           ...state,
           loading: false,
           item: action.payload,
        }
      default:
        return state;
    }
  };
  
  export default goodReducer;