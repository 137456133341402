export const START_PARAM_DETECTED = 'START_PARAM_DETECTED';
export const INIT_APP = 'INIT';
export const AUTHENTIFICATED ='AUTHENTIFICATED';
export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const BIND_BUTTONS = 'BIND_BUTTONS';
export const BIND_MAIN_BTN = 'BIND_MAIN_BTN';
export const BIND_BACK_BTN = 'BIND_BACK_BTN';
export const FINISH_LOADING = 'FINISH_LOADING';
export const ERROR_LOADING = 'ERROR_LOADING'; 
export const SAVE_END_INDEX = 'SAVE_END_INDEX';
export const SAVE_SCROLL_TOP = 'SAVE_SCROLL_TOP';
export const SET_SELECTED_GOOD = 'SET_SELECTED_GOOD';
export const HIDE_GOOD = 'HIDE_GOOD';
export const CHECK_START_PARAM = 'CHECK_START_PARAM';
export const SET_SELECTED_SECTION = 'SET_SELECTED_SECTION';

export const initApp = () => ({
    type: INIT_APP
  });
export const authenticated = () => ({
  type: AUTHENTIFICATED
});
export const finishLoading = () => ({
  type: FINISH_LOADING
});
export const errorLoading = (response) => ({
  type:ERROR_LOADING,
  payload: {
    message: response,
    code: response.status
  }
});
export const bindButtons = () => ({
  type: BIND_BUTTONS
});
export const bindMainBtn = () => ({
  type: BIND_MAIN_BTN
});
export const bindBackBtn = () => ({
  type: BIND_BACK_BTN
});
export const saveUserInfo = (userData) => ({
    type: SAVE_USER_INFO,
    payload: {
        email: userData?.email,
        avatarUrl: userData?.avatar_url,
        firstName: userData?.first_name,
        lastName: userData?.last_name,
        surname: userData?.surname,
    },
});
export const updateUserInfo = (userData) => ({
    type: UPDATE_USER_INFO,
    payload: {
        newFirstName: userData.firstName,
        newLastName: userData.lastName,
        newSurname: userData.surname,
    }
});
export const saveEndIndex = (endIndex) => ({
  type: SAVE_END_INDEX,
  payload: endIndex
}); 
export const saveScrollTop = (scrollTop) => ({
  type: SAVE_SCROLL_TOP,
  payload: scrollTop
});
export const setSelectedGood = (id) => ({
  type: SET_SELECTED_GOOD,
  payload: id,
});
export const hideGood = () => ({
  type: HIDE_GOOD,
  payload: -1,
}) 
export const checkStartParam = () => ({
  type: CHECK_START_PARAM,
});

export const setSelectedSection = (section) => ({
    type: SET_SELECTED_SECTION,
    payload: section,
});