import $api from '../../http';
import {finishFetchingCatalogData} from "../actions/catalogActions";

export const catalogData = (useCloudStorageResult, useTelegramResult) => async (dispatch) => {
    const response = await $api.get('/goods');
    if(response.status === 200){
        //Перемешиваем случайным образом
        const shuffledCards = shuffleArray(response.data);
        //Упорядочиваем новый порядок
        const cardsWithOrderId = shuffledCards.map((card, index) => ({
        ...card,
        order_id: index + 1, // Нумерация с 1
        }));
        dispatch(finishFetchingCatalogData(cardsWithOrderId));
        
    }
};
const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
};