import axios from 'axios';
import {api_addr} from './urls';


const getItem = (key, callback) => {
    window.Telegram.WebApp.CloudStorage.getItem(key, (error, value) => {
        if (error === null){
            if (value !== null) {
                callback(value);
            } else {
                console.error(`Значение для токена не найдено`);
                callback(null);
            }
        } else{
            console.error(`Ошибка: ${error}`);
            callback(null);
        }
    });
};
const getItemAsync = (key) => {
    return new Promise((resolve, reject) => {
        getItem(key, (value) => {
            resolve(value);
        });
    });
};
const setItem = (key, value) => {
    window.Telegram.WebApp.CloudStorage.setItem(key, value, (result) => {
        // Callback-функция вызывается после завершения операции
        if (result !== null) {
            console.error(`Ошибка при установке токена в хранилище cloudStorage: ${result}`);
        }
    });
};
const $api = axios.create({
    baseURL: api_addr
});
$api.interceptors.request.use(async (config) => {
    const token = await getItemAsync('accessToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});
$api.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = await getItemAsync('refreshToken'); 
                const response = await axios.post(`${api_addr}/refresh`, {}, {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`
                    }
                });

                const { accessToken, refreshToken: newRefreshToken } = response.data;

                await setItem('accessToken', accessToken); 
                await setItem('refreshToken', newRefreshToken); 
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                return $api(originalRequest);
            } catch (error) {
                console.error('Refresh token failed', error);
                await setItem('accessToken', ''); 
                await setItem('refreshToken', ''); 
                throw error;
                
            }
        }
        return Promise.reject(error);
    }
);

export default $api;