import { INIT_CATALOG, FETCH_CATALOG_DATA, FINISH_FETCHING_CATALOG_DATA, APPLY_FILTERS, SHOW_FAVORITES } from "../actions/catalogActions";
import { SET_SELECTED_SECTION } from "../actions/appActions";
const initialState = {
    loading: true,
    error: null,
    cards: [],
    sectionCards:[],
    filteredCards: [],
}
const catalogReducer = (state = initialState, action) => {
    switch(action?.type){
        case INIT_CATALOG:
            return{
                ...state,
            };
        case FETCH_CATALOG_DATA:
            return{
                ...state,
            }
        case FINISH_FETCHING_CATALOG_DATA:
            return{
                ...state,
                loading: false,
                cards: action.payload,
                filteredCards: action.payload,
            }
        case APPLY_FILTERS: 
            return {
                ...state,
                filteredCards: action.payload,
            };
        case SHOW_FAVORITES:
            const { flag, favorites } = action.payload;
            if (flag) {
                const filteredFavorites = state.filteredCards.filter(card => favorites.includes(card.id));
                console.log(filteredFavorites);
                return {
                    ...state,
                    filteredCards: filteredFavorites,
                };
            } else {
                return {
                    ...state,
                    filteredCards: state.sectionCards,
                };
            }
        case SET_SELECTED_SECTION:
            const selectedSection = action.payload;
            const sectionCards = selectedSection 
                ? state.cards.filter(card => selectedSection.categories.includes(card.categoryName))
                : state.cards;
            return {
                ...state,
                filteredCards: sectionCards,
                sectionCards: sectionCards,
            };
        default:
        return state;
    }
}
export default catalogReducer;