import rootReducer from './reducers/index';
import {thunk} from 'redux-thunk';
import combinedMiddleware from './middleware';
import { createStore, combineReducers, applyMiddleware } from 'redux';

const configureStore = (cloudStorageResult, telegramResult) => {
    const middleware = applyMiddleware(thunk, combinedMiddleware(cloudStorageResult, telegramResult));
    const store = createStore(rootReducer, middleware);
    return store;
};

export default configureStore;