// thunks/basketThunks.js
import { addToBasket, removeFromBasket, updateBasketItem,
    submitOrderRequest, submitOrderSuccess, submitOrderFailure
 } from '../actions/basketActions';
 import $api from '../../http';
import { bot_url_with_param, manager } from '../../http/urls';


export const addToBasketAsync = (item) => (dispatch) => {
    dispatch(addToBasket(item));
};

export const removeFromBasketAsync = (itemId) => (dispatch) => {
    dispatch(removeFromBasket(itemId));
};

export const updateBasketItemAsync = (itemId, quantity) => (dispatch) => {
    dispatch(updateBasketItem(itemId, quantity));
};

export const submitOrder = (useCloudStorageResult, useTelegramResult) => async (dispatch, getState) => {
    const { setItem, getItemAsync } = useCloudStorageResult;  
    const { webApp, lang } = useTelegramResult;
    const state = getState();
    const items = state?.basket?.items;
    dispatch(submitOrderRequest());
    const { user } = webApp.initDataUnsafe;

    const orderData = {
        ClientId: user?.id,
        OrderPositions: items.map(item => ({
            GoodId: item.id,
            Amount: item.quantity,
            Price: item.price,
        })),
    };
    console.log(orderData);
    const confirmOrder = async () => {
        dispatch(submitOrderRequest());
        try {
            const response = await $api.post('/order', orderData);
            dispatch(submitOrderSuccess());
            webApp.showPopup({
                message: 'Заказ успешно создан!',
            });
            const contact = await getItemAsync('contact');
            if(!contact){
                var orderList = ' -------------------------------------------- ';
                items.forEach(item => {
                    orderList += `${item.name} - ${item.quantity} шт. - ${item.price} руб. - ${bot_url_with_param}${item.link} --------------------------------------------  `;
                });
                const messageTemplate = `Id: ${webApp.initDataUnsafe?.user?.id.toString()} Товары: ${orderList}`;
                const operator_url = `https://t.me/${manager}?text=${messageTemplate}`;
                const text = `Так как вы не поделились контактом, отправьте менеджеру сформированное сообщение, чтобы с вами смогли связаться.`;
                const params = {
                    message: text,
                    buttons: [
                        {
                            id: '13',
                            text: "Отправить",
                        },
                    ],
                }; 
                webApp.showPopup(params, async (action) => {
                    if(action === params?.buttons[0]?.id){
                        webApp.openTelegramLink(operator_url);
                    }
                });
            }
        } catch (error) {
            dispatch(submitOrderFailure(error));
            webApp.showPopup({
                message: 'Произошла ошибка при создании заказа.',
            });
        }
    };

    webApp.showPopup({
        message: 'Вы подтверждаете оформление заказа?',
        buttons: [
            { id: '2', type: 'cancel', text: 'Нет' },
            { id: '1', type: 'ok', text: 'Да' }
            
        ]
    }, (buttonId) => {
        if (buttonId === '1') {
            confirmOrder();
        }
    });
};
