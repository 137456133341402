// actions/basketActions.js
export const INIT_BASKET = 'INIT_BASKET';
export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const REMOVE_FROM_BASKET = 'REMOVE_FROM_BASKET';
export const UPDATE_BASKET_ITEM = 'UPDATE_BASKET_ITEM';
export const SHOW_BASKET = 'SHOW_BASKET';
export const HIDE_BASKET = 'HIDE_BASKET';
export const SUBMIT_ORDER_REQUEST = 'SUBMIT_ORDER_REQUEST';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_FAILURE = 'SUBMIT_ORDER_FAILURE';
export const CLEAR_BASKET = 'CLEAR_BASKET';

export const initBasket = () => ({
    type: INIT_BASKET,
});
export const addToBasket = (item) => ({
    type: ADD_TO_BASKET,
    payload: item,
});

export const removeFromBasket = (itemId) => ({
    type: REMOVE_FROM_BASKET,
    payload: itemId,
});

export const updateBasketItem = (itemId, quantity) => ({
    type: UPDATE_BASKET_ITEM,
    payload: { itemId, quantity },
});

export const showBasket = () => ({
    type: SHOW_BASKET
});

export const hideBasket = () => ({
    type: HIDE_BASKET
});

export const submitOrderRequest = () => ({
    type: SUBMIT_ORDER_REQUEST,
});

export const submitOrderSuccess = () => ({
    type: SUBMIT_ORDER_SUCCESS,
});

export const submitOrderFailure = (error) => ({
    type: SUBMIT_ORDER_FAILURE,
    payload: error,
});
export const clearBasket = () => ({
    type: CLEAR_BASKET,
});