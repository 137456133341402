import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Favorite from '../favorite/Favorite';
import ImageCard from '../imageCard/ImageCard';
import {saveEndIndex, saveScrollTop, setSelectedGood } from '../../store/actions/appActions';
import classes from "./Card.module.css";
import {useTelegram} from "../../hooks/useTelegram";

const truncateName = (name, maxLength) => {
    if (name.length > maxLength) {
      return `${name.slice(0, maxLength - 3)}...`;
    }
    return name;
  };

const Card = ({appRef, endIndex, id, name, photoUrl, price}) => {
    const dispatch = useDispatch();

    const saveScreenPosition = () => {
        dispatch(saveEndIndex(endIndex));
        dispatch(saveScrollTop(appRef?.current?.scrollTop));
    };  
    const handleCardClick = () => {
        saveScreenPosition();
        dispatch(setSelectedGood(id));
    };

    return (
        <div className={classes._} onClick={handleCardClick}>
            <ImageCard photo={photoUrl} />
            <Favorite id={id} />
            <div className={classes.info}>
                <div className={classes.block}>
                    <p className={classes.price}>{price} ₽</p>
                </div>
                <div className={classes.block_low}>
                    <p className={classes.loc}>{truncateName(name, 38)}</p>
                </div>
            </div>
        </div>
    );
};

export default Card;