// favoriteReducer.js
import {SET_FAVORITES, SET_FAVORITE, UPDATE_FAVORITE} from '../actions/favoriteActions';

const initialState = {
    goods: [],
  };
  
  const favoriteReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_FAVORITES:
        return {
          ...state,
          goods: action.payload,
        };
      case SET_FAVORITE:
        const { id, isFavorite } = action.payload;
        if (!isFavorite) {
          return {
            ...state,
            goods: state.goods.filter(favoriteId => favoriteId !== id),
          };
        } else {
          return {
            ...state,
            goods: [...new Set([...state.goods, id])],
          };
        }
      case UPDATE_FAVORITE:
        return {
          ...state,
        }
      default:
        return state;
    }
  };
  
  export default favoriteReducer;
  